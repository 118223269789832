import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import {
  ModalPart,
  PatientInfo,
  HorizontalLine,
  InfoType,
} from "../../pages/RadAdmin/FollowUpList/PatientDetailsModal";
import { formatDate as dateFormat } from "../../../src/utils/utils";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validator from "validator";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";

export const PatientInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid #cacaca;
  border-radius: 8px;
  background: #fafafa;
  padding: 20px;
`;

export const InfoBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`;


const StyledLink = styled(Link)`
 color:#00AEEF;
 font-size:14px;
 font-weight:400;
 text-decoration:underline;
`

const useStyles = makeStyles((theme) => ({
  inputFieldsSection: {
    display: "flex",
    marginBottom: "10px",
  },
  heading: {
    fontSize: "22px",
    fontWeight: 700,
    color: "#0C2945",
    fontFamily: "Roboto Condensed",
    marginBottom: "15px",
  },
  helperText: {
    fontSize: 11,
  },
}));

const InfoBlock = ({ blockName, children }) => {
  return (
    <InfoBlockContainer>
      <InfoType style={{ marginBottom: "4px", fontSize:'12px', color:'#6D6E6E' }}>{blockName}</InfoType>
      <PatientInfo
        style={{
          fontSize: "16px",
          color: "#000000",
          fontFamily: "Open Sans",
          wordBreak: "break-word",
        }}
      >
        {children}
      </PatientInfo>
    </InfoBlockContainer>
  );
};

const phoneClosedOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];
const phoneUnsubscribedOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];

const IndicatorsContainer = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};


export default function EditFormTop({
  state,
  handleChange,
  handlePhoneChange,
  handlePhoneChangePhysician,
  handleFaxChangePhysician,
  isValid, 
  setIsValid,
  isValidPhysicianEmail,
  setIsValidPhysicianEmail,
  handlePatientClosedChange,
  handlePatientUnsubscribedChange
}) {
  const classes = useStyles();
  return (
    <>
      <Grid>
        <Typography className={classes.heading}>Patient Details</Typography>
        <Grid className={classes.inputFieldsSection}>
          <ModalPart style={{ width: "100%" }}>
            <PatientInfoContainer
              style={{ gap: "30px", justifyContent: "flex-start" }}
            >
              <InfoBlock blockName={"FIRST NAME"}>
                {state.ptFirstName || "-"}
              </InfoBlock>
              <InfoBlock blockName={"LAST NAME"}>
                {state.ptLastName || "-"}
              </InfoBlock>
              <InfoBlock blockName={"PATIENT MRN"}>
              <StyledLink
               to={`/auth/radAdmin/followUpList/patients/patientDetails/${state.ptId}`}
               target='_blank'
               onClick={(e)=>{
               e.stopPropagation();
               window.analytics.track('Edit-Reco-Patient-MRN-Click',{patientId: state.ptId})
               }}
               >
                {state.ptMrn || "-"}
               </StyledLink>
              </InfoBlock>
              <InfoBlock blockName={"DATE OF BIRTH"}>
                {dateFormat(state.ptDOB) || "-"} ({state.ptAge || "-"})
              </InfoBlock>
              <InfoBlock blockName={"EMAIL"}>
                <input
                  type="text"
                  id="email"
                  name="ptEmail"
                  value={state.ptEmail}
                  onChange={(e) => {
                    const inputEmail = e.target.value;
                    const isValidEmail = validator.isEmail(inputEmail);
                    setIsValid(isValidEmail);
                    handleChange(e);
                  }}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #D2D3D7",
                    padding: "9px 12px",
                    height: "38px",
                    marginTop:'4px',
                    width:'100%'
                  }}
                />
                {!isValid && state.ptEmail && (
                  <p style={{ color: "red", margin:'5px 0px' }}>Invalid Email Address</p>
                )}
              </InfoBlock>
              <InfoBlock blockName={"PHONE NUMBER"}>
                <PhoneInput
                  country={"us"}
                  value={state?.ptPhone || ""}
                  onChange={handlePhoneChange}
                  onlyCountries={["us"]}
                  countryCodeEditable={true}
                  disableDropdown={false}
                  inputStyle={{ height: "38px", width: "100%" }}
                  containerStyle={{ width: "100%", marginTop:'4px' }}
                />
              </InfoBlock>
              <InfoBlock blockName={"PHONE CLOSED"}>
              <Select
                  components={{Option: IndicatorsContainer }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={phoneClosedOptions}
                  value={state?.ptPhoneClosed}
                  onChange={handlePatientClosedChange}
                  styles={{
                    option: (styles) => ({ ...styles, fontSize: "14px",fontWeight:500 }),
                    control: (styles) => ({
                      ...styles,
                      fontWeight:500
                    }),
                    menu: (styles) => ({
                      ...styles,
                      fontWeight:500
                    }),
                  }}
                />
              </InfoBlock>
              <InfoBlock blockName={"PHONE UNSUBSCRIBED"}>
              <Select
                  components={{Option: IndicatorsContainer }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={phoneUnsubscribedOptions}
                  value={state?.ptPhoneUnsubscribed}
                  onChange={handlePatientUnsubscribedChange}
                  styles={{
                    option: (styles) => ({ ...styles, fontSize: "14px",fontWeight:500  }),
                    control: (styles) => ({
                      ...styles,
                      fontWeight:500
                    }),
                    menu: (styles) => ({
                      ...styles,
                      fontWeight:500
                    }),
                  }}
                />
              </InfoBlock>
              
            </PatientInfoContainer>
          </ModalPart>
        </Grid>
      </Grid>

      <Grid>
        <Typography className={classes.heading}>Referring Physician</Typography>
        <Grid className={classes.inputFieldsSection}>
          <ModalPart style={{ width: "100%" }}>
            <PatientInfoContainer
              style={{ gap: "30px", justifyContent: "flex-start" }}
            >
              <InfoBlock blockName={"FIRST NAME"}>
                {state.physicianFirstName || "-"}
              </InfoBlock>
              <InfoBlock blockName={"LAST NAME"}>
                {state.physicianLastName || "-"}
              </InfoBlock>
              <InfoBlock blockName={"NPI"}>
                {state.physicianNpi || "-"}
              </InfoBlock>
              <InfoBlock blockName={"EMAIL"}>
                <input
                  type="text"
                  id="email"
                  name="physicianEmail"
                  value={state.physicianEmail}
                  onChange={(e) => {
                    const inputEmail = e.target.value;
                    const isValidEmail = validator.isEmail(inputEmail);
                    setIsValidPhysicianEmail(isValidEmail);
                    handleChange(e);
                  }}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #D2D3D7",
                    padding: "9px 12px",
                    height: "38px",
                    marginTop:'4px',
                    width:'100%'
                  }}
                />
                {!isValidPhysicianEmail && state.physicianEmail && (
                  <p style={{ color: "red", margin:'5px 0px' }}>Invalid Email Address</p>
                )}
              </InfoBlock>
              <InfoBlock blockName={"PHONE NUMBER"}>
                <PhoneInput
                  country={"us"}
                  value={state?.physicianPhone || ""}
                  onChange={handlePhoneChangePhysician}
                  onlyCountries={["us"]}
                  countryCodeEditable={true}
                  disableDropdown={false}
                  inputStyle={{ height: "38px", width: "100%" }}
                  containerStyle={{ width: "100%", marginTop:'4px' }}
                />
              </InfoBlock>
              <InfoBlock blockName={"FAX NUMBER"}>
                <PhoneInput
                  country={"us"}
                  value={state?.physicianFax || ""}
                  onChange={handleFaxChangePhysician}
                  onlyCountries={["us"]}
                  countryCodeEditable={true}
                  disableDropdown={false}
                  inputStyle={{ height: "38px", width: "100%" }}
                  containerStyle={{ width: "100%", marginTop:'4px' }}
                />
              </InfoBlock>
            </PatientInfoContainer>
          </ModalPart>
        </Grid>
      </Grid>
      <Grid>
        <Typography className={classes.heading}>Radiologist</Typography>
        <Grid className={classes.inputFieldsSection}>
          <ModalPart style={{ width: "100%" }}>
            <PatientInfoContainer
              style={{ gap: "30px", justifyContent: "flex-start" }}
            >
              <InfoBlock blockName={"FIRST NAME"}>
                {state.radFirstName || "-"}
              </InfoBlock>
              <InfoBlock blockName={"LAST NAME"}>
                {state.radLastName || "-"}
              </InfoBlock>
              <InfoBlock blockName={"EMAIL"}>{state.radEmail || "-"}</InfoBlock>
              <InfoBlock blockName={"PHONE NUMBER"}>
                {state.radPhone || "-"}
              </InfoBlock>
            </PatientInfoContainer>
          </ModalPart>
        </Grid>
      </Grid>
    </>
  );
}
