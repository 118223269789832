import React from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const StyledTooltip = (props) => {
  const { className, ...other } = props;

  return (
    <Tooltip
      {...other}
      PopperProps={{
        className: className,
        style: {
          backgroundColor: "azure",
          marginLeft: "20px",
          borderRadius: "4px",
        },
      }}
    />
  );
};

const StyledTooltipWrapper = withStyles(() => ({
  tooltip: {
    width: 300,
    backgroundColor: "transparent",
    color: "black",
    border: "1px solid #00AEEF",
    padding: "0px",
    margin: "0px",
    "&::before": {
      content: "''",
      position: "absolute",
      top: "calc(50%)",
      left: "-13px",
      borderStyle: "solid",
      borderWidth: 0,
      borderLeftWidth: "12px",
      borderRightWidth: "12px",
      borderBottomWidth: "22px",
      borderColor: "transparent transparent azure transparent",
      transform: "translateY(-50%) rotate(270deg)",
      backgroundClip: "padding-box",
      zIndex: "1",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: "calc(50%)",
      left: "-14.5px",
      borderStyle: "solid",
      borderWidth: "0 8px 15px 8px",
      borderColor: "transparent transparent #00AEEF transparent",
      zIndex: "0",
      transform: "translateY(-50%) rotate(270deg)",
    },
  },
}))(StyledTooltip);

const CardList = styled.ul`
  max-height: 400px;
  overflow-y: auto;
  list-style: none;
  width: 298px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0px;
`;

const Container = styled.div`
  margin-bottom: 0px;
`;

const Title = styled.p`
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  color: #0C2945;
  line-height: 18px;
`;

const Description = styled.p`
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: #252733;
  line-height: 18px;
`;

const RecommendationsHeader = styled.p`
  color: #00AEEF;
  font-size: 16px;
  font-weight: 700;
  padding: 12px;
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

function capitalizeWords(str) {
    return str
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

const RcommendationsDescriptionCard = ({ title, description }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>
        {Object.keys(description).map((itm) => (
          <div>
            {" "}
            {capitalizeWords(itm?.replaceAll("_", " "))} : {(description[itm] !== null)? capitalizeWords(String(description[itm])?.replace(/_/g,' ')) : '-'}
          </div>
        ))}
      </Description>
    </Container>
  );
};


export const RecommendationTooltip = ({ children, recommendations }) => {
  const headerLabel = "Recommendations";

  return (
    <>
      <StyledTooltipWrapper
        interactive={true}
        title={
          <>
            {headerLabel && <RecommendationsHeader>{headerLabel}</RecommendationsHeader>}
            <CardList>
              {recommendations.map((i, index) => (
                <li key={index}>
                  <RcommendationsDescriptionCard
                    title={`Recommendation ${index + 1}:`}
                    description={i}
                  />
                </li>
              ))}
            </CardList>
          </>
        }
        placement={"right"}
      >
        {children}
      </StyledTooltipWrapper>
    </>
  );
};
