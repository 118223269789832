import React, { useState } from "react";
import styled from "styled-components";

import { CloseIcon } from "./Icons";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router";
import {  ReactComponent as RecoMoveLeft} from '../../../assets/LeftChevron.svg'
import {  ReactComponent as RecoMoveRight} from '../../../assets/RightChevron.svg'
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import {  ReactComponent as CircledAddIcon} from '../../../assets/circled-add-icon.svg'
import CreateRecommendationsModal from "./CreateRecommendationsModal";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../../actions/radminUploads";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
 
  .chevron-left,
  .chevron-right {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
}

.chevron-left {
  left: 2%; 
}

.chevron-right {
  right: 2%; 
}

`;

const ModalContentContainer = styled.div`
  position: relative;
  min-height: 400px;
  width: 80%;
  height: 80%;
  overflow-y: scroll;
  margin: 0 auto;
  background-color: white;
  cursor: default;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  border-radius: 8px;
  padding: 30px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const ModalTitle = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 24px;
  color: #0c2945;
  font-family: Roboto Condensed;
  font-weight: 700;
`;

const CloseButton = styled.button`
  border: 0;
  outline: none !important;
  background-color: #ffffff;
`;

const Label = styled.h4`
  color: #9fa2b4;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align:left;
`;

const Text = styled.span`
  color: ${(props) => (props.color ? props.color : "#252733")};
  font-size: 14px;
  font-weight: 400;
  text-align:left;
`;

const ReportText = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align:left;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const LoadingContainer = styled.div`
display:flex;
align-items:center;
height:100%;
justify-content:center;
`

const IconContainer = styled.span`
cursor: ${props=>props.isDisabled ?"not-allowed" :"pointer"}
`

const StyledLink = styled(Link)`
 color:#00AEEF;
 font-size:14px;
 font-weight:400;
 text-decoration:underline;
`

const StyledCreateRecoButton = styled.button`
background:#06739D;
padding:9.5px 11.5px;
color:white;
border-radius:4px;
border:none;
font-size:14px;
font-weight:700;
display:flex;
gap:10px;
align-items:center;
`

const useStyles = makeStyles({
  rowStyling: {
    width: "100%",
    margin: "0px 0px 12px 0px",
  },
  addCircle: {
    cursor: "pointer",
    color: "#4dabf5",
  },
});

const ViewReportModal = ({ showModal, closeModal, data, handleNext=()=>{}, handlePrev=()=>{}, isPaginated=false, disabledPrev=false, disabledNext=false, isLoading=false, showCreateRecommendations=false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.auth.user.permission_level); 
 const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
 const createPermission = rolePermissions?.find(itm=>itm.name ==="Search Report Text")?.permissions.find(itm=>itm.codename === "add_searchreporttext")?.is_authorized;

  const [showCreateRecommendationsModal, setShowCreateRecommendationsModal] = useState(false);

  const textContent = data?.report_text
    ?.replace(/\r\n/g, "<br>")
    ?.replace(/\r/g, "<br>")
    ?.replace(/\n/g, "<br>");

  return (
    <>
      <Modal showModal={showModal}>
       {
        isPaginated && !showCreateRecommendationsModal && <>
        <IconContainer isDisabled={disabledPrev} onClick={handlePrev} className="chevron-left">
        <RecoMoveLeft/>
        </IconContainer>
        <IconContainer isDisabled={disabledNext} onClick={handleNext} className="chevron-right">
        <RecoMoveRight/>
        </IconContainer>
        </>
       }
   
        <ModalContentContainer>
          {
            isLoading ? 
            <LoadingContainer>
             <Spinner animation="border" role="status" size="sm" /> 
            </LoadingContainer>
             :<>
             <Header>
            <ModalTitle>Report Text</ModalTitle>
            <div style={{display:'flex', gap:'38px'}}>
            {showCreateRecommendations && <StyledCreateRecoButton onClick={()=>{
                if((permission && createPermission) || !permission){
                  setShowCreateRecommendationsModal(true)
                  window.analytics.track('Recommendation-Create-Button-Click');
                }else{
                  dispatch(
                    showAlert('danger', 'Error', `You don't have permission to create recommendation`)
                  );
                }
           
              }}>Create Recommendation <CircledAddIcon/></StyledCreateRecoButton> }
            <CloseButton type={"button"} onClick={closeModal}>
              <CloseIcon />
            </CloseButton>  
            </div>
          
          </Header>
          <div style={{ display: "flex", gap: "86px", marginBottom: "30px" }}>
            <Col>
              <Label>Accession Number</Label>
              <Text>{data?.accession_number || '-'}</Text>
            </Col>
            <Col>
              <Label>Patient MRN</Label>
              <StyledLink
              to={`/auth/radAdmin/followUpList/patients/patientDetails/${data?.patient?.id}`}
              target='_blank'
              onClick={(e)=>{
                e.stopPropagation();
                window.analytics.track(`Report-View-Patient-MRN-Click`);
            }}
            >
            {data?.patient?.mrn || '-'}
            </StyledLink>
            </Col>
            <Col>
              <Label>Reco Modality</Label>
              <Text>{data?.modality || '-'}</Text>
            </Col>
            <Col>
              <Label>Exam Date</Label>
              <Text>
                { (data?.initial_exam_date && data?.initial_exam_date !== '-')? moment(data?.initial_exam_date).format("YYYY-MM-DD") : '-'}
              </Text>
            </Col>
            <Col>
              <Label>location</Label>
              <Text>
                {data?.location_address || '-'}
              </Text>
            </Col>
          </div>
          <Col>
            <Label>Report Text</Label>
            <ReportText dangerouslySetInnerHTML={{ __html: textContent }} />
          </Col>
            </>
          }
         
        </ModalContentContainer>
     
      </Modal>
      {
          showCreateRecommendationsModal && <CreateRecommendationsModal data={data}  showModal={showCreateRecommendationsModal}
          closeModal={() => {
            setShowCreateRecommendationsModal(false)
          }} 
          closeParentModal={closeModal}
          />
        }
    </>
  );
};

export default ViewReportModal;
