import styled from "styled-components";
import Flex from "../../../components/_styles/Flex";

export const DEFAULT_VALUES = {
  chart_type: "",
  data_type: "",
  data_subset: "",
  frequency: "",
  flow_type: "",
  group_by: "",
  compare_preceeding: false,
  additional: {
    recommendations: {
      status: "",
      timeframe_status_extracted: "",
      modality: "",
      anatomy: "",
      age: "",
      message_sent: "",
      has_note: "",
      location_address: "",
      report__radiologist__id: "",
      report__modality: "",
      modality_type: {
        value: true,
        label: "Include",
        category: "modality_type",
      },
      anatomy_type: { value: true, label: "Include", category: "anatomy_type" },
      report__modality_type: {
        value: true,
        label: "Include",
        category: "report__modality_type",
      },
      initial_exam_date_gte: "",
      initial_exam_date_lte: "",
      nlp_range_start_gte: "",
      nlp_range_start_lte: "",
      nlp_range_end_gte: "",
      nlp_range_end_lte: "",
      exam_code: "",
      exam_code_type: {
        value: true,
        label: "Include",
        category: "exam_code_type",
      },
    },
    reports: {
      modality: "",
      radiologist: "",
      message_sent: "",
      modality_type: {
        value: true,
        label: "Include",
        category: "modality_type",
      },
    },
    patients: {},
    referrers: {},
    radiologists: {},
    emails: {
      status: "",
    },
    sms: {
      direction: "",
      status: "",
    },
    notes: {
      task: "",
    },
    "recommendations-pie": {
      status: "",
      timeframe_status_extracted: "",
      modality: "",
      anatomy: "",
      age: "",
      message_sent: "",
      has_note: "",
      location_address: "",
      report__radiologist__id: "",
      report__modality: "",
      modality_type: {
        value: true,
        label: "Include",
        category: "modality_type",
      },
      anatomy_type: { value: true, label: "Include", category: "anatomy_type" },
      report__modality_type: {
        value: true,
        label: "Include",
        category: "report__modality_type",
      },
      initial_exam_date_gte: "",
      initial_exam_date_lte: "",
      nlp_range_start_gte: "",
      nlp_range_start_lte: "",
      nlp_range_end_gte: "",
      nlp_range_end_lte: "",
      exam_code: "",
      exam_code_type: {
        value: true,
        label: "Include",
        category: "exam_code_type",
      },
    },
    notes: {},
    "reco-history": {
      user: "",
      status_override_from: "",
      status_override_to: "",
    },
    appointments: {
      modailty: "",
    },
    prescriptions: {
      modailty: "",
    },
  },
};

export const timeseriesChartTypeOptions = [
  { value: "bar", label: "Bar", category: "chart_type" },
  { value: "line", label: "Line", category: "chart_type" },
];

export const nonTimeSeriesChartTypeOptions = [
  { value: "pie", label: "Pie", category: "chart_type" },
  { value: "gauge", label: "Gauge", category: "chart_type" },
];

export const frequencyOptions = [
  { value: "days", label: "Per Day", category: "frequency" },
  { value: "weeks", label: "Per Week", category: "frequency" },
  { value: "months", label: "Per Month", category: "frequency" },
  { value: "years", label: "Per Year", category: "frequency" },
];
export const dataTypeOptions = [
  { value: "reports", label: "Reports", category: "data_type" },
  {
    value: "recommendations",
    label: "Recommendations - Time Series",
    category: "data_type",
  },
  {
    value: "recommendations-pie",
    label: "Recommendations - Non Time Series",
    category: "data_type",
  },
  { value: "patients", label: "Patients", category: "data_type" },
  { value: "radiologists", label: "Radiologists", category: "data_type" },
  { value: "referrers", label: "Referrers", category: "data_type" },
  { value: "sms", label: "Sms", category: "data_type" },
  { value: "emails", label: "Emails", category: "data_type" },
  { value: "notes", label: "Notes", category: "data_type" },
  { value: "reco-history", label: "Reco History", category: "data_type" },
  { value: "appointments", label: "Appointments", category: "data_type" },
  { value: "prescriptions", label: "Prescriptions", category: "data_type" },
];

export const recommendationTimeframeStatusOptions = [
  { value: "no_timeframe_identified", label: "No Timeframe Identified" },
  { value: "incomplete_missing_end", label: "Incomplete Missing End" },
  { value: "incomplete_missing_start", label: "Incomplete Missing Start" },
  { value: "complete_timeframe", label: "Complete Timeframe" },
];

export const emailsStatusOptions = [
  { value: "sent", label: "Sent" },
  { value: "delivered", label: "Delivered" },
  { value: "opened", label: "Opened" },
  { value: "clicked", label: "Clicked" },
  { value: "spammed", label: "Spammed" },
  { value: "failed", label: "Failed" },
  { value: "dropped", label: "Dropped" },
  { value: "bounced", label: "Bounced" },
  { value: "unsubscribed", label: "Unsubscribed" },
  { value: "converted", label: "Converted" },
  { value: "attempted", label: "Attempted" },
];

export const smsDirectionOptions = [
  { value: "all", label: "Inbound & Outbound" },
  { value: "inbound", label: "Inbound" },
  { value: "outbound", label: "Outbound" },
];

export const smsStatusOptions = [
  { value: "drafted", label: "Drafted" },
  { value: "attempted", label: "Attempted" },
  { value: "sent", label: "Sent" },
  { value: "delivered", label: "Delivered" },
  { value: "clicked", label: "Clicked" },
  { value: "converted", label: "Converted" },
  { value: "bounced", label: "Bounced" },
  { value: "failed", label: "Failed" },
  { value: "undeliverable", label: "Undeliverable" },
];

export const dataSubsetReportsOptions = [
  {
    value: "initial_exam_date",
    label: "Initial exam date",
    category: "data_subset",
  },
  {
    value: "charge",
    label: "Charges",
    category: "data_subset",
  },
  {
    value: "message_sent_created",
    label: "Message Sent Created",
    category: "data_subset",
  },
];

export const dataSubsetRecoHistoryOptions = [
  {
    value: "changed_at",
    label: "Changed At",
    category: "data_subset",
  },
];

export const dataSubsetPrescriptionsOptions = [
  {
    value: "ordered_time",
    label: "Ordered Time",
    category: "data_subset",
  },
];
export const dataSubsetAppointmentsOptions = [
  {
    value: "initial_exam_date",
    label: "Initial Exam Date",
    category: "data_subset",
  },
  {
    value: "scheduled_date",
    label: "Scheduled Date",
    category: "data_subset",
  },
];

export const dataSubsetRecommendationsOptions = [
  {
    value: "range_start",
    label: "Nlp Range Start",
    category: "data_subset",
  },
  { value: "range_end", label: "Nlp Range End", category: "data_subset" },
  {
    value: "initial_exam_date",
    label: "Report Initial Exam Date",
    category: "data_subset",
  },
];

export const dataSubsetRecommendationsPieOptions = [
  { value: "modality", label: "Modality", category: "data_subset" },
  { value: "anatomy", label: "Anatomy", category: "data_subset" },
  {
    value: "modality,anatomy",
    label: "Modality & Anatomy",
    category: "data_subset",
  },
  { value: "conditional", label: "Conditional", category: "data_subset" },
  {
    value: "report__radiologist__first_name",
    label: "Radiologist",
    category: "data_subset",
  },
  {
    value: "report__message_sent",
    label: "Message Sent",
    category: "data_subset",
  },
  { value: "guideline_type", label: "Guideline Type", category: "data_subset" },
  { value: "user_rejected", label: "User Rejected", category: "data_subset" },
  {
    value: "timeframe_status_extracted",
    label: "Timeframe Status Extracted",
    category: "data_subset",
  },
];

export const dataSubsetPatientsOptions = [
  { value: "gender", label: "Gender", category: "data_subset" },
  {
    value: "phone_unsubscribed",
    label: "Phone Unsubscribed",
    category: "data_subset",
  },
  { value: "phone_closed", label: "Phone Closed", category: "data_subset" },
];
export const dataSubsetRadiologistsOptions = [
  { value: "quality", label: "Quality", category: "data_subset" },
  { value: "none", label: "Non-Quality", category: "data_subset" },
];
export const dataSubsetReferrersOptions = [
  { value: "quality", label: "Quality", category: "data_subset" },
  { value: "none", label: "Non-Quality", category: "data_subset" },
];

export const dataSubsetSmsOptions = [
  { value: "created", label: "Created at", category: "data_subset" },
];
export const dataSubsetEmailsOptions = [
  { value: "created", label: "Created at", category: "data_subset" },
];
export const dataSubsetNotesOptions = [
  { value: "created", label: "Created at", category: "data_subset" },
];

export const PIECOLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export const messageSentOptions = [
  { value: "true", label: "true", category: "message_sent" },
  { value: "false", label: "false", category: "message_sent" },
];
export const noteOptions = [
  { value: "true", label: "true", category: "has_note" },
  { value: "false", label: "false", category: "has_note" },
];
export const ageOptions = [
  { value: "18-34", label: "18-34", category: "age" },
  { value: "35-64", label: "35-64", category: "age" },
  { value: "65+", label: "65+", category: "age" },
];

export const timeframeOptions = [
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "Last 3 months", label: "Last 3 months" },
  { value: "Last 6 months", label: "Last 6 months" },
  { value: "Last 12 months", label: "Last 12 months" },
  { value: "Custom date range", label: "Custom date range" },
];

export const timeframeOptionsPrecedingDay = [
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
];

export const modalityTypeOptions = [
  { value: true, label: "Include", category: "modality_type" },
  { value: false, label: "Exclude", category: "modality_type" },
];

export const anatomyTypeOptions = [
  { value: true, label: "Include", category: "anatomy_type" },
  { value: false, label: "Exclude", category: "anatomy_type" },
];

export const reportModalityTypeOptions = [
  { value: true, label: "Include", category: "report__modality_type" },
  { value: false, label: "Exclude", category: "report__modality_type" },
];

export const examTypeOptions = [
  { value: true, label: "Include", category: "exam_code_type" },
  { value: false, label: "Exclude", category: "exam_code_type" },
];

export const reportsGroupOptions = [
  { value: "modality", label: "Modality", category: "status" },
  { value: "location_address", label: "Location", category: "status" },
];

export const recommendationsGroupOptions = [
  { value: "status", label: "Status", category: "status" },
  { value: "modality", label: "Modality", category: "status" },
  { value: "anatomy", label: "Anatomy", category: "status" },
];
export const notesGroupOptions = [
  { value: "task", label: "Task", category: "status" },
  { value: "user__email", label: "User", category: "status" },
];

export const emailGroupOptions = [
  { value: "status", label: "Status", category: "status" },
];

export const smsGroupOptions = [
  { value: "status", label: "Status", category: "status" },
];

export const recoHistoryGroupOptions = [
  { value: "user__email", label: "User", category: "status" },
  {
    value: "status_override_from",
    label: "Status Override From",
    category: "status",
  },
  {
    value: "status_override_to",
    label: "Status Override To",
    category: "status",
  },
];

export const prescriptionsGroupOptions = [
  { value: "modality", label: "Modality", category: "status" },
];
export const appointmentsGroupOptions = [
  { value: "modality", label: "Modality", category: "status" },
];

export const flowTypeOptions = [
  { value: "compare", label: "Compare Charts", category: "flow_type" },
  { value: "individual", label: "Individual Charts", category: "flow_type" },
];

export const dataTypeComparisonOptions = [
  { value: "reports", label: "Reports", category: "data_type" },
  { value: "recommendations", label: "Recommendations", category: "data_type" },
  { value: "sms", label: "Sms", category: "data_type" },
  { value: "emails", label: "Emails", category: "data_type" },
  { value: "notes", label: "Notes", category: "data_type" },
  { value: "reco-history", label: "Reco History", category: "data_type" },
  { value: "prescriptions", label: "Prescriptions", category: "data_type" },
  { value: "appointments", label: "Appointments", category: "data_type" },
];

export const recoStatusOptions = [
  { value: "empty", label: "Empty" },
  { value: "navigator_closed", label: "Navigator Closed" },
  { value: "patient_deceased", label: "Patient Deceased" },
  { value: "unsubscribed", label: "Unsubscribed" },
  { value: "acknowledged", label: "Acknowledged" },
  { value: "not_clinically_necessary", label: "Not Clinically Necessary" },
  { value: "completed_elsewhere", label: "Completed Elsewhere" },
  { value: "scheduled_not_completed", label: "Scheduled Not Completed" },
  { value: "completed_before_timeframe", label: "Completed Before Timeframe" },
  { value: "completed_after_timeframe", label: "Completed After Timeframe" },
  { value: "needs_prior_auth", label: "Needs Prior Authorization" },
  { value: "needs_prescription", label: "Needs Prescription" },
  { value: "adherent", label: "Adherent" },
  { value: "rx_received", label: "RX Received" },
  { value: "non_mature", label: "Non Mature" },
  { value: "coming_due", label: "Coming Due" },
  { value: "overdue", label: "Overdue" },
  { value: "expired", label: "Non-Compliant" },
  { value: "unknown", label: "Unknown" },

];

export const taskOptions = [
  { value: "call", label: "Call" },
  { value: "fax", label: "Fax" },
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "letter", label: "Letter" },
  { value: "general", label: "General" },
];

export const smsColors = (status) => {
  status = status?.toLowerCase();

  return status === "sent"
    ? "#FDBB13"
    : status === "delivered"
    ? "#64C67C"
    : status === "bounced"
    ? "#FF5F65"
    : status === "drafted"
    ? "#00AEEF"
    : status === "attempted"
    ? "#DFE0EB"
    : status === "clicked"
    ? "#4EAC9F"
    : status === "converted"
    ? "#FF7A00"
    : status === "failed"
    ? "#9B64C6"
    : "#C2C2C2";
};

export const emailColors = (status) => {
  status = status?.toLowerCase();

  return status === "converted"
    ? "#FF7A00"
    : status === "unsubscribed"
    ? "#ADADAD"
    : status === "bounced"
    ? "#FF5F65"
    : status === "dropped"
    ? "#CF3131"
    : status === "failed"
    ? "#9B64C6"
    : status === "spammed"
    ? "#7C7C7C"
    : status === "clicked"
    ? "#4EAC9F"
    : status === "opened"
    ? "00AEEF"
    : status === "delivered"
    ? "#64C67C"
    : status === "sent"
    ? "#FDBB13"
    : status === "attempted"
    ? "#DFE0EB"
    : `#c3c3c3`;
};

export const taskColors = (status) => {
  status = status?.toLowerCase();

  return status === "call"
    ? "#FDBB13"
    : status === "fax"
    ? "#64C67C"
    : status === "letter"
    ? "#FF5F65"
    : status === "email"
    ? "#00AEEF"
    : status === "general"
    ? "#DFE0EB"
    : status === "sms"
    ? "#4EAC9F"
    : status === "empty"
    ? "#FF7A00"
    : "#C2C2C2";
};

export const recommendationStatusColors = (status) => {
  status = status?.toLowerCase();
  return status === "coming_due"
    ? "#ed8e66"
    : status === "adherent"
    ? "#64C67C"
    : status === "overdue"
    ? "#3e61d7"
    : status === "non_mature"
    ? "#DFE0EB"
    : status === "not_clinically_necessary"
    ? "#FFD57C"
    : status === "completed_elsewhere"
    ? "#6F52ED"
    : status === "expired"
    ? "#6f42c1"
    : status === "acknowledged"
    ? "#00AEEF"
    : status === "unsubscribed"
    ? "#F73B3B"
    : status === "unresponsive"
    ? "#808080"
    : status === "needs_prior_auth"
    ? "#DD6ECD"
    : status === "needs_prescription"
    ? "#FF5F65"
    : status === "rx_received"
    ? "#186EF0"
    : status === "navigator_closed"
    ? "#A7A9AD"
    : status === "patient_deceased"
    ? "#2E2F30"
    : status === "scheduled_not_completed"
    ? "#FF7A00"
    : status === "completed_after_timeframe"
    ? "#87c25c"
    : status === "completed_before_timeframe"
    ? "#3b772c"
    : status === "unknown"
    ? "#94721a"
    : `#b1c939`;
};

export const recoHistoryStatusColors = (status) => {
  return status === "coming_due"
    ? "#ed8e66"
    : status === "adherent"
    ? "#64C67C"
    : status === "overdue"
    ? "#3e61d7"
    : status === "non_mature"
    ? "#DFE0EB"
    : status === "not_clinically_necessary"
    ? "#FFD57C"
    : status === "completed_elsewhere"
    ? "#6F52ED"
    : status === "non_compliant"
    ? "#6f42c1"
    : status === "acknowledged"
    ? "#00AEEF"
    : status === "unsubscribed"
    ? "#F73B3B"
    : status === "unresponsive"
    ? "#808080"
    : status === "needs_prior_auth"
    ? "#DD6ECD"
    : status === "needs_prescription"
    ? "#FF5F65"
    : status === "rx_received"
    ? "#186EF0"
    : status === "navigator_closed"
    ? "#A7A9AD"
    : status === "patient_deceased"
    ? "#2E2F30"
    : status === "scheduled_not_completed"
    ? "#FF7A00"
    : `#b1c939`;
};


export function randomColor(index) {
  const colorsArray = [
    "#ed8e66",
    "#3e61d7",
    "#6eb729",
    "#9366e9",
    "#4fc656",
    "#9b40b5",
    "#b1c939",
    "#6e49bc",
    "#bab71b",
    "#3c4dbb",
    "#91a824",
    "#cd6ee4",
    "#42952e",
    "#c734a1",
    "#53c984",
    "#e862c4",
    "#87c25c",
    "#5581f4",
    "#e8a532",
    "#554baa",
    "#d3b73f",
    "#8268d0",
    "#7b993a",
    "#b085e7",
    "#3b772c",
    "#e3418f",
    "#40cba6",
    "#d4335b",
    "#40a160",
    "#a53b90",
    "#b6bc61",
    "#8253ab",
    "#ab972e",
    "#4067be",
    "#e1651b",
    "#4a9de3",
    "#bf371a",
    "#4cbee0",
    "#cb333b",
    "#62cfbe",
    "#eb5284",
    "#3b8251",
    "#d378c7",
    "#445a06",
    "#788ce0",
    "#e3852e",
    "#3868a9",
    "#c18523",
    "#644999",
    "#757913",
    "#5d5ca7",
    "#94721a",
    "#8d4b96",
    "#8fbc7c",
    "#aa2e6a",
    "#55a47e",
    "#ec5f4f",
    "#31a49d",
    "#d96536",
    "#4f8dbb",
    "#af6426",
    "#a8a5e5",
    "#545608",
    "#d699dc",
    "#5e7222",
    "#9576ba",
    "#6e6e23",
    "#725392",
    "#dea35a",
    "#5d679c",
    "#ccba7c",
    "#995790",
    "#6d9050",
    "#c76290",
    "#296437",
    "#e77994",
    "#115e41",
    "#c04758",
    "#287e63",
    "#ec95bd",
    "#415a1f",
    "#b377a2",
    "#536c31",
    "#8d476f",
    "#226a4d",
    "#e07a6c",
    "#525620",
    "#90425d",
    "#9a914f",
    "#963f49",
    "#6c6a34",
    "#e2928f",
    "#705d18",
    "#a65f68",
    "#765113",
    "#b85e5d",
    "#916e39",
    "#a1492b",
    "#ce9568",
    "#90562d",
  ];

  return colorsArray[index];
}

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #c2ccd9;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

export const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin: 15px 15px 50px 0px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitleAndLegend = styled(Flex)`
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-family: Roboto Condensed;
  padding-left: 20px;
  padding-right: ${(props) => props.right};
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const PieChartContainer = styled.div`
  margin-top: -26px;
  @media (max-width: 768px) {
    margin: 10px 5px;
  }
`;

export const LegendListContainer = styled.div`
  height: fit-content;
  width: fit-content;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -20px;
    display: block;
    width: 20px;
    height: 1px;
    background-color: #c3c7ca;
  }
`;

export const LegendList = styled.ul`
  height: 100%
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  font-weight: normal;
  color: #000000;
  margin-bottom:0;
  &:first-child {
    position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        display: block;
        width: 7px;
        height: 1px;
        background-color: #C3C7CA;
      }
  }
  &:after {
    content: '';
    position: absolute;
    left: -10px;
    bottom: 0;
    display: block;
    width: 7px;
    height: 1px;
    background-color: #C3C7CA;
`;

export const LegendListItem = styled.li`
  position: relative;
  margin-bottom: 10px;
  &:before {
    content: "";
    position: absolute;
    top: 7px;
    left: -17px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.color ? props.color : "black")};
  }
`;

export const RecoPercent = styled.span`
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  color: #0c2945;
`;

export const FilterContainer = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  margin-right: 9px;
`;

export const NoDataContainer = styled.div`
  margin: 0 auto;
  height: 320px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoDataText = styled.span`
  color: black;
`;

export const SelectContainer = styled.div`
  width: 227px;
  margin-bottom: 14px;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

export const SidebarContainer = styled.div`
  width: 271px;
  border: 1px solid #dfdfdf;
  padding: 10px 29px 0px 15px;
  background: white;
  height: 100vh;
  overflow-y: auto;
`;

export const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 16px;
`;

export const DateFilterContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  display: ${(props) =>
    props.isSelected !== undefined
      ? props.isSelected
        ? "block"
        : "none"
      : "block"};
`;

export const TimeframeContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    padding: 0px;
    & > div {
      height: 43.6px;
      & > span > div > .select__control {
        height: 43.6px;
      }
      & > span > div {
        height: 43.6px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const COLORS = [
  "#ed8e66",
  "#64C67C",
  "#3e61d7",
  "#9B64C6",
  "#FFD57C",
  "#A7A9AD",
];

export const legendWrapperStyle = {
  top: "50%",
  transform: "translateY(-50%)",
  right: -20,
  height: "fit-content",
  width: "fit-content",
  lineHeight: "24px",
  borderLeft: "1px solid #C3C7CA",
  paddingLeft: "10px",
};

export const Container = styled.div`
  & .recharts-legend-wrapper {
    max-height: ${(props) => (props.isTimeGraph ? "200px!important" : "auto")};
    overflow-y: ${(props) => (props.isTimeGraph ? "auto" : "none")};
  }
`;

export const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  height: 32px;
  margin-right: 7px;
  margin-top: 2px;
  padding: 4px 10px 4px 10px;
  background: #eeeeee;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

export const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #a1a1a1;
`;

export const Button = styled.button`
  background: #00aeef;
  color: white;
  border-radius: 4px;
  border: 2px solid #00aeef;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  width: 183px;

  &:hover {
    color: #00aeef;
    background: transparent;
  }
`;

export const desiredOrderRecoHistory = [
  "User",
  "Status Override From",
  "Status Override To",
];
export const desiredOrderSms = ["Status"];
export const desiredOrderEmails = ["Status"];
export const desiredOrderRecommendations = ["Status", "Modality", "Anatomy"];
export const desiredOrderNotes = ["User", "Task"];
export const desiredOrderAppointments = ["Modality"];
export const desiredOrderPrescriptions = ["Modality"];
export const desiredOrderReports = ["Location", "Modality"];
