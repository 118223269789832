import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "./Icons";
import { Dialog, makeStyles } from "@material-ui/core";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  createRecommendationFromSearchReport,
  getAnatomiesListAction,
  getDropdownValuesForCohort,
  getModalitiesListAction,
} from "../../../actions/radminUploads";
import { forwardRef } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar-outline.svg";
import { ReactComponent as RemoveRecoIcon } from "../../../assets/close-outline.svg";
import Flex from "../../../components/_styles/Flex";
import Creatable from "react-select/creatable";

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const ModalTitle = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 24px;
  color: #0c2945;
  font-family: Roboto Condensed;
  font-weight: 700;
`;

const CloseButton = styled.button`
  border: 0;
  outline: none !important;
  background-color: #ffffff;
`;

const Label = styled.h4`
  color: #9fa2b4;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: left;
`;

const Text = styled.span`
  color: ${(props) => (props.color ? props.color : "#252733")};
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;

const ReportText = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

const IconContainer = styled.span`
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
`;

const StyledLink = styled(Link)`
  color: #00aeef;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
`;

const useStyles = makeStyles({
  paper: {
    minWidth: "80%",
    minHeight: "80%",
    padding: "30px",
  },
});

const SelectContainer = styled.div`
  width: ${(props) => props.width || "227px"};
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const Row = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  // width: 230px;
`;

const Button = styled.button`
  width: 163px;
  height: 38px;
  background-color: ${(props) => (props.isPrimary ? "#00aeef" : "transparent")};
  border-radius: 4px;
  border: ${(props) => (props.isPrimary ? "0" : "2px solid #CFCFCF")};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => (props.isPrimary ? "#FFFFFF" : "#000000")};
  display: block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  &:hover {
    background-color: ${(props) =>
      props.isPrimary ? "transparent" : "#CFCFCF"};
    border: ${(props) => (props.isPrimary ? "2px solid #00aeef" : "0")};
    color: ${(props) => (props.isPrimary ? "#00aeef" : "#ffffff")};
  }
`;

const RowContainer = styled.div`
  margin-bottom: 30px;
`;

const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  font-size: 18px;
  width: 227px;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const DateContainer = styled(Flex)`
  margin-right: 15px;
  font-size: 14px;
  color: #828282;
  font-family: Open Sans;
`;

const DateClearButton = styled.div`
  cursor: pointer;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 60px;
`;

const timeframeStatusOptions = [
  {
    value: "no_timeframe_identified",
    label: "No Timeframe Identified",
    category: "timeframe_status_extracted",
  },
  {
    value: "incomplete_timeframe_missing_end",
    label: "Incomplete Missing End",
    category: "timeframe_status_extracted",
  },
  {
    value: "incomplete_timeframe_missing_start",
    label: "Incomplete Missing Start",
    category: "timeframe_status_extracted",
  },
  {
    value: "complete_timeframe_identified",
    label: "Complete Timeframe",
    category: "timeframe_status_extracted",
  },
];

const testOptions = [
  { value: "screening", label: "Screening", category: "exam_type" },
  { value: "diagnostic", label: "Diagnostic", category: "exam_type" },
];

const recommemdationOptions = [
  {
    value: "imaging_followup",
    label: "Imaging Followup",
    category: "recommendation_type",
  },
  {
    value: "non_imaging_followup",
    label: "Non Imaging Followup",
    category: "recommendation_type",
  },
];

const contrastOptions = [
  { value: "with", label: "With", category: "contrast" },
  {
    value: "without",
    label: "Without",
    category: "contrast",
  },
  {
    value: "with-without",
    label: "With and Without",
    category: "contrast",
  },
];

const CreateRecommendationsModal = ({
  showModal,
  closeModal,
  data,
  closeParentModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogRef = useRef(null);
  const [savingRecommendation, setSavingRecommendation] = useState(false);

  const [selectedValues, setSelectedValues] = useState({
    startDate: "",
    endDate: "",
    modality: "",
    anatomy: "",
    timeframe_status_extracted: "",
    guideline_type: "",
    conditional: "",
    exam_type: "",
    recommendation_type: "",
    contrast: "",
  });
  const [optionsModality, setOptionsModality] = useState();
  const [optionsAnatomy, setOptionsAnatomy] = useState();

  const fixedValues = useSelector(
    (state) => state.radAdmin.cohorts.fixedValues?.data
  );

  const modalities = useSelector(
    (state) => state.radAdmin.followUpList.modalities.data
  );
  const anatomies = useSelector(
    (state) => state.radAdmin.followUpList.anatomies.data
  );

  const guidelineOptions = fixedValues?.length
    ? fixedValues
        .find((val) => val.attribute === "guideline_type")
        ?.values?.map((itm) => ({
          value: itm,
          label: itm,
          category: "guideline_type",
        }))
    : [];

  const conditionalOptions = [
    { value: "true", label: "true", category: "conditional" },
    { value: "false", label: "false", category: "conditional" },
  ];

  const modalityOptions = modalities?.length
    ? [].concat(
        modalities
          .map((item) => ({ value: item, label: item, category: "modality" }))
          .filter((item) => item.value)
      )
    : [];

  const anatomyOptions = anatomies?.length
    ? [].concat(
        anatomies
          .map((item) => ({ value: item, label: item, category: "anatomy" }))
          .filter((item) => item.value)
      )
    : [];

  useEffect(() => {
    dispatch(getModalitiesListAction());
    dispatch(getAnatomiesListAction());
    dispatch(getDropdownValuesForCohort("Recommendation", "guideline_type"));
  }, []);

  useEffect(() => {
    setOptionsModality(modalityOptions);
  }, [modalities]);

  useEffect(() => {
    setOptionsAnatomy(anatomyOptions);
  }, [anatomies]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setSavingRecommendation(true);
    const mergedFilters = {
      user_range_start: selectedValues["startDate"]
        ? moment(selectedValues["startDate"]).format("YYYY-MM-DD")
        : null,
      user_range_end: selectedValues["endDate"]
        ? moment(selectedValues["endDate"]).format("YYYY-MM-DD")
        : null,
      user_anatomy:
        selectedValues["anatomy"]?.value === "null"
          ? null
          : selectedValues["anatomy"]?.value,
      user_modality:
        selectedValues["modality"]?.value === "null"
          ? null
          : selectedValues["modality"]?.value,
      contrast: selectedValues["contrast"]?.value,
      conditional: selectedValues["conditional"]?.value,
      guideline_type: selectedValues["guideline_type"]?.value,
      exam_type: selectedValues["exam_type"]?.value,
      recommendation_type: selectedValues["recommendation_type"]?.value,
      timeframe_status_extracted:
        selectedValues["timeframe_status_extracted"]?.value,
    };

    dispatch(
      createRecommendationFromSearchReport({
        report: data.report_id,
        ...mergedFilters,
      })
    ).then((res) => {
      setSavingRecommendation(false);
      if (res) {
        closeModal();
        closeParentModal();
      }
    });
  };

  const IndicatorsContainer = (props) => {
    return (
      <div style={{ backgroundColor: "#FFF" }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const RadioOption = (props) => {
    return (
      <>
        <components.Option {...props}>
          <span style={{ marginRight: 5, paddingTop: 5 }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => null}
              style={{ marginTop: 5 }}
            />{" "}
          </span>
          <label>{props.label}</label>
        </components.Option>
      </>
    );
  };

  const resetDateFilterStartDate = () => {
    setSelectedValues((prev) => ({ ...prev, startDate: null, endDate: null }));
  };

  const resetDateFilterEndDate = () => {
    setSelectedValues((prev) => ({ ...prev, endDate: null }));
  };

  const DatePickerInputStartDate = forwardRef(({ onClick, startDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };

    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {!startDate ? "Select..." : `${formatDate(startDate)}`}
        </DateContainer>
        {!startDate && <CalendarIcon />}
        {startDate && (
          <DateClearButton onClick={resetDateFilterStartDate}>
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const DatePickerInputEndDate = forwardRef(({ onClick, endDate }, ref) => {
    const formatDate = (date) => {
      return date ? moment(date).format("MMM, DD, YYYY") : "No Date";
    };

    return (
      <DatePickerButton onClick={onClick} ref={ref}>
        <DateContainer>
          {!endDate ? "Select..." : `${formatDate(endDate)}`}
        </DateContainer>
        {!endDate && <CalendarIcon />}
        {endDate && (
          <DateClearButton onClick={resetDateFilterEndDate}>
            <RemoveRecoIcon />
          </DateClearButton>
        )}
      </DatePickerButton>
    );
  });

  const handleChangeModality = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      const newOption = { value: newValue.value, label: newValue.label };
      setOptionsModality((prevOptions) => [...prevOptions, newOption]);
    }
    setSelectedValues((prev) => ({ ...prev, modality: newValue }));
  };

  const handleChangeAnatomy = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      const newOption = { value: newValue.value, label: newValue.label };
      setOptionsAnatomy((prevOptions) => [...prevOptions, newOption]);
    }
    setSelectedValues((prev) => ({ ...prev, anatomy: newValue }));
  };

  return (
    <>
      <Dialog
        open={showModal}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.paper }}
        onClose={closeModal}
        PaperProps={{
          ref: dialogRef,
        }}
      >
        <>
          <Header>
            <ModalTitle>Create Recommendations</ModalTitle>
            <div>
              <CloseButton type={"button"} onClick={closeModal}>
                <CloseIcon />
              </CloseButton>
            </div>
          </Header>
          <div style={{ display: "flex", gap: "86px", marginBottom: "30px" }}>
            <Col>
              <Label>Accession Number</Label>
              <Text>{data?.accession_number || "-"}</Text>
            </Col>
            <Col>
              <Label>Patient MRN</Label>
              <StyledLink
                to={`/auth/radAdmin/followUpList/patients/patientDetails/${data?.patient?.id}`}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                  window.analytics.track(`Report-View-Patient-MRN-Click`);
                }}
              >
                {data?.patient?.mrn || "-"}
              </StyledLink>
            </Col>
            <Col>
              <Label>Reco Modality</Label>
              <Text>{data?.modality || "-"}</Text>
            </Col>
            <Col>
              <Label>Exam Date</Label>
              <Text>
                { (data?.initial_exam_date && data?.initial_exam_date !== '-') ? moment(data?.initial_exam_date).format("MM-DD-YYYY") : "-"}
              </Text>
            </Col>
            <Col>
              <Label>location</Label>
              <Text>{data?.location_address || "-"}</Text>
            </Col>
          </div>

          <div>
            <FormContainer>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <RowContainer>
                    <Row>
                      <SelectContainer>
                        <SelectDropdownTitle>
                          Reco Min Date
                        </SelectDropdownTitle>
                        <DatePicker
                          selected={selectedValues?.startDate}
                          customInput={
                            <DatePickerInputStartDate
                              startDate={selectedValues?.startDate}
                            />
                          }
                          onChange={(date) =>
                            setSelectedValues((prev) => ({
                              ...prev,
                              startDate: date,
                              endDate: null,
                            }))
                          }
                          popperPlacement="auto-right"
                       
                        />
                      </SelectContainer>

                      <SelectContainer>
                        <SelectDropdownTitle>
                          Reco Max Date
                        </SelectDropdownTitle>
                        <DatePicker
                          selected={selectedValues?.endDate}
                          customInput={
                            <DatePickerInputEndDate
                              endDate={selectedValues?.endDate}
                            />
                          }
                          onChange={(date) =>
                            setSelectedValues((prev) => ({
                              ...prev,
                              endDate: date,
                            }))
                          }
                          popperPlacement="top-end"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "5px, 10px",
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                          minDate={selectedValues?.startDate}
                        />
                      </SelectContainer>
                    </Row>
                    <Row>
                      <SelectContainer>
                        <SelectDropdownTitle>
                          Recommended Modality
                        </SelectDropdownTitle>

                        <Creatable
                          isClearable={true}
                          onChange={handleChangeModality}
                          options={optionsModality}
                          value={selectedValues["modality"]}
                          placeholder="Type or Select..."
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </SelectContainer>
                      <SelectContainer>
                        <SelectDropdownTitle>
                          Recommended Anatomy
                        </SelectDropdownTitle>

                        <Creatable
                          isClearable={true}
                          onChange={handleChangeAnatomy}
                          options={optionsAnatomy}
                          value={selectedValues["anatomy"]}
                          placeholder="Type or Select..."
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </SelectContainer>

                      <SelectContainer>
                        <SelectDropdownTitle>Test Type *</SelectDropdownTitle>
                        <Select
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={testOptions}
                          value={selectedValues["exam_type"]}
                          onChange={(value) =>
                            setSelectedValues({
                              ...selectedValues,
                              exam_type: value,
                            })
                          }
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable={true}
                        />
                      </SelectContainer>
                      <SelectContainer>
                        <SelectDropdownTitle>Contrast</SelectDropdownTitle>
                        <Select
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={contrastOptions}
                          value={selectedValues["contrast"]}
                          onChange={(value) =>
                            setSelectedValues({
                              ...selectedValues,
                              contrast: value,
                            })
                          }
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable={true}
                        />
                      </SelectContainer>
                    </Row>
                    <Row>
                      <SelectContainer>
                        <SelectDropdownTitle>
                          Guideline Type
                        </SelectDropdownTitle>
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={guidelineOptions}
                          value={selectedValues["guideline_type"]}
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          onChange={(value) =>
                            setSelectedValues({
                              ...selectedValues,
                              guideline_type: value,
                            })
                          }
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable={true}
                        />
                      </SelectContainer>
                      <SelectContainer>
                        <SelectDropdownTitle>Conditional *</SelectDropdownTitle>
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={conditionalOptions}
                          value={selectedValues["conditional"]}
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          onChange={(value) =>
                            setSelectedValues({
                              ...selectedValues,
                              conditional: value,
                            })
                          }
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable={true}
                        />
                      </SelectContainer>
                      <SelectContainer>
                        <SelectDropdownTitle>
                          Recommended Timeframe
                        </SelectDropdownTitle>
                        <Select
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={timeframeStatusOptions}
                          value={selectedValues["timeframe_status_extracted"]}
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          onChange={(value) =>
                            setSelectedValues({
                              ...selectedValues,
                              timeframe_status_extracted: value,
                            })
                          }
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable={true}
                        />
                      </SelectContainer>

                      <SelectContainer>
                        <SelectDropdownTitle>
                          Recommendation Type *
                        </SelectDropdownTitle>
                        <Select
                          components={{
                            IndicatorsContainer,
                            Option: RadioOption,
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={recommemdationOptions}
                          value={selectedValues["recommendation_type"]}
                          onChange={(value) =>
                            setSelectedValues({
                              ...selectedValues,
                              recommendation_type: value,
                            })
                          }
                          styles={{
                            option: (styles) => ({
                              ...styles,
                              fontSize: "14px",
                            }),
                          }}
                          isClearable={true}
                        />
                      </SelectContainer>
                    </Row>
                  </RowContainer>

                  <ButtonContainer>
                    <Button onClick={closeModal}>Cancel</Button>

                    <Button
                      isPrimary={true}
                      disabled={
                        !selectedValues?.recommendation_type ||
                        !selectedValues?.exam_type ||
                        !selectedValues?.conditional ||
                         savingRecommendation
                      }
                    >
                      {" "}
                      {savingRecommendation ? (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                          style={{ margin: "0 10px" }}
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </ButtonContainer>
                </div>
              </form>
            </FormContainer>
          </div>
        </>
      </Dialog>
    </>
  );
};

export default CreateRecommendationsModal;
