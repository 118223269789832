import React, { useState, useCallback } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  deleteSavedFilterRecommendations,
  deleteSavedSearchesData,
  getSavedSearchesData,
  updateSavedFilterRecommendations,
  updateSavedSearchesData,
} from "../../../actions/radminUploads";
import { Spinner } from "react-bootstrap";
import { showAlert } from "../../../actions/radminUploads";


const deleteIcon = `${process.env.PUBLIC_URL}/${"DeleteButton.svg"}`;
const deleteIconWhite = `${process.env.PUBLIC_URL}/${"delete-icon-white.svg"}`;
const cancelOutline = `${process.env.PUBLIC_URL}/${"cancel-outline.svg"}`;

ReactModal.setAppElement("#root");

const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 386px !important;
  margin: 15% auto;
  background: white;
  padding: 20px 24px !important;
  height: ${({ height }) => height};
  @media (max-width: 768px) {
    width: auto !important;
    height: auto !important;
    margin: 30px !important;
    margin-top: calc(15% + 30px) !important;
  }
`;

const Label = styled.label`
  align-self: flex-start;
  margin: 0px 0px 8px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3f4857;
  margin-top: 20px;
`;

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: ${({ width }) => (width ? width : "104px")};
  height: 38px;
  background: ${({ isPrimary, disabled, isDelete }) =>
    disabled
      ? "#00AEEF4D"
      : isPrimary
      ? "#00AEEF"
      : isDelete
      ? "#CF3131"
      : "transparent"};
  border-radius: 5px;
  border: ${({ isPrimary, isDelete }) =>
    isPrimary || isDelete ? "none" : "2px solid #CFCFCF"};
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isPrimary, isDelete }) =>
    isPrimary || isDelete ? "white" : "#000000"};
`;

const customStyle =  {
  overlay: {
      backdropFilter: 'blur(10px)',
      backgroundColor:'rgba(12, 41, 69, 0.38)',
      zIndex:1
  },
  content:{
    borderRadius:'4px'
  }
} ;

const EditModal = ({ opened, onClose, selectedValues,  setSavedSearches,
    setNextPage }) => {
  const [filterSetName, setFilterSetName] = useState(
    selectedValues?.label || ""
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const permission = useSelector((state) => state.auth.user.permission_level); 
  const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
  const updatePermission = rolePermissions?.find(itm=>itm.name ==="Search Report Text")?.permissions.find(itm=>itm.codename === "change_searchreporttext")?.is_authorized;
  const deletePermission = rolePermissions.find(itm=>itm.name ==="Search Report Text")?.permissions.find(itm=>itm.codename === "delete_searchreporttext")?.is_authorized;

  const onChangeFilterName = (e) => {
    setFilterSetName(e.target.value);
  };

  const handleFilterSave = async (e) => {
    e.preventDefault();
    if((permission && updatePermission) || !permission){
      setLoading(true);
      window.analytics.track("Saved-Search-Edit-Filter-Set-Submit", {
        id: selectedValues.id,
        title: filterSetName,
      });
      await dispatch(
        updateSavedSearchesData({
          id: selectedValues.id,
          name: filterSetName,
        })
      );
      await dispatch(getSavedSearchesData(1)).then((res) => {
          setSavedSearches(
            res?.results?.map((itm) => ({
              label: itm?.name || itm.search_text,
              value: itm.id,
              category: "search",
              id: itm.id,
              filters:itm.filters || [],
              exclude: itm.neg_search_text,
              search: itm.search_text,
            }))
          );
          if (res?.next) {
            setNextPage(2);
          }
        });
      setLoading(false);
      onClose();
    }else{
      dispatch(
        showAlert('danger', 'Error', `You don't have permission to update search report text`)
      );
    }
  };

  const handleDeleteClicked = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteData = async () => {
    if((permission && deletePermission) || !permission){
      window.analytics.track("Saved-Search-Delete-Filter-Set-Submit", {
        id: selectedValues.id,
      });
      setIsDeleting(true);
      await dispatch(deleteSavedSearchesData(selectedValues.id));
      await dispatch(getSavedSearchesData(1)).then((res) => {
          setSavedSearches(
            res?.results?.map((itm) => ({
              label: itm?.name || itm.search_text,
              value: itm.id,
              category: "search",
              id: itm.id,
              filters:itm.filters || [],
              exclude: itm.neg_search_text,
              search: itm.search_text,
            }))
          );
          if (res?.next) {
            setNextPage(2);
          }
        });
      setIsDeleting(false);
      onClose();
    }else{
      dispatch(
        showAlert('danger', 'Error', `You don't have permission to delete search report text`)
      );
    }
  };

  const onCloseDeleteView = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <Modal
      height={showDeleteConfirmation ? "175px !important" : "213px !important"}
      isOpen={opened}
      onRequestClose={onClose}
      style={customStyle}
    >
      {showDeleteConfirmation ? (
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2
              style={{
                fontSize: "24px",
                color: "#0C2945",
                fontWeight: 700,
                marginBottom: "0px",
                fontFamily: "Roboto Condensed",
              }}
            >
              Delete Saved Search?
            </h2>
            <img
              style={{
                cursor: "pointer",
                marginBottom: "0px",
                textAlign: "right",
                height: "14px",
                display: "inline-block",
              }}
              onClick={onClose}
              src={cancelOutline}
            />
          </div>

          <h2
            style={{
              fontSize: "14px",
              marginBottom: "20px",
              marginTop: "20px",
              fontWeight: 400,
              color: "#000000",
            }}
          >
            Are you sure you want to delete{" "}
            <span style={{ textDecoration: "underline" }}>
              {selectedValues?.label}
            </span>
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "24px",
              justifyContent: "center",
            }}
          >
            <Button
              width="125px"
              onClick={onCloseDeleteView}
              children="No, Keep It"
            />

            <Button
              width="125px"
              isDelete={true}
              onClick={handleDeleteData}
              children={
                isDeleting ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <span>Yes, Delete It</span>
                  </div>
                )
              }
            />
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h2
              style={{
                fontSize: "24px",
                color: "#0C2945",
                fontWeight: 700,
                marginBottom: "0px",
                fontFamily: "Roboto Condensed",
              }}
            >
              Edit Saved Search
            </h2>
            <img
              style={{
                cursor: "pointer",
                marginBottom: "0px",
              }}
              onClick={onClose}
              src={cancelOutline}
            />
          </div>
          <form style={{ width: "100%" }} onSubmit={handleFilterSave}>
            <Label children="Saved Search Name" htmlFor="filterSet" />
            <Input
              onChange={onChangeFilterName}
              value={filterSetName}
              id="filterSet"
              placeholder="Saved Search Name"
            />
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button style={{flex:1}} onClick={onClose} children="Cancel" />

              <Button
               style={{flex:1}}
                isDelete={true}
                onClick={handleDeleteClicked}
                children={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <span>Delete</span>
                    <img
                      style={{
                        cursor: "pointer",
                        marginBottom: "0px",
                        textAlign: "right",
                        height: "14px",
                        display: "inline-block",
                      }}
                      src={deleteIconWhite}
                    />
                  </div>
                }
              />
              <Button
                style={{flex:1}}
                isPrimary={true}
                disabled={!filterSetName}
                type="submit"
                children={
                  loading ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    "Save"
                  )
                }
              />
            </div>
          </form>
        </>
      )}
    </Modal>
  );
};

export default EditModal;
