import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import {
  exportNotes,
  getNoteUsers,
  getNotePatients,
  getNoteTitle,
} from "../../../actions/radminUploads";
import { useDispatch } from "react-redux";
import moment from "moment";
import Flex from "../../../components/_styles/Flex";
import Filter from "../Messages/Filter";
import Select, { components } from "react-select";
import debounce from "../FollowUpList/FollowUpListFilter";
import { Spinner } from "react-bootstrap";

ReactModal.setAppElement("#root");

const Modal = styled(ReactModal)`
  height:398px !important;
  align-items: center;
  padding: 25px !important;
  margin: 15% auto;
  background: white;
  width: 40% !important;


  @media (max-width: 768px) {
    width: auto !important;
    margin: 30px !important;
    margin-top: calc(15% + 30px) !important;
  }
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6D6E6E;
  text-transform: uppercase;
`;


const TimeframeContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    padding: 0px;
    width:100%;
    & > div {
        width: 100%;
        height:43.6px;
        & >span > div > .select__control {
            height:43.6px;
        }
        & > span> div {
            height: 43.6px;
        }
       
    }
}

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DatePickerContainer = styled(Flex)`
  width: 235px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const DatePickerButton = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d2d3d7;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const DateContainer = styled(Flex)`
  margin-right: 15px;
`;



const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const options = [
  { value: "Last 7 days", label: "Last 7 days" },
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "Last 3 months", label: "Last 3 months" },
  { value: "Last 12 months", label: "Last 12 months" },
  { value: "Custom date range", label: "Custom date range" },
];

const taskOptions = [
  { value: "call", label: "Call" },
  { value: "fax", label: "Fax" },
  { value: "email", label: "Email" },
  { value: "sms", label: "SMS" },
  { value: "letter", label: "Letter" },
  {value:'general',label:'General'}
];

const recipientOptions = [
  { value:'patient', label:'Patient'},
  {value:'referring_physician', label:'Referring Physician'},
  {value:'internal', label:'Internal'},
  {value:'None', label:'None'}
]


const IndicatorsContainer = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span style={{ marginRight: 5, paddingTop: 5 }}>
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}</label>
      </components.Option>
    </>
  );
};


const customStyle =  {
  overlay: {
      backdropFilter: 'blur(10px)',
      backgroundColor:'rgba(12, 41, 69, 0.38)',
      zIndex:1
  },
} ;

const NotesExportModal = ({ opened, onClose }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const dispatch = useDispatch();
  const [userOptions, setUserOptions] = useState();
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const [selectedUsers, setSelectedUsers] = useState("");
  const [query, setQuery] = useState("");
  const [patientsList, setPatientsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryTitle, setQueryTitle] = useState("");
  const [titleList, setTitleList] = useState([]);
  const [loadingTitle, setLoadingTitle] = useState(false);
  const [selectedTask, setSelectedTask] = useState("")
  const [selectedRecipient, setSelectedRecipient] = useState("");
  const [savingNotes, setSavingNotes] = useState(false);

  React.useEffect(() => {
    if (filterValue) {
      var date = new Date();
      let requiredDate;
      let lastMoment;
      if (filterValue.value === "Custom date range") return;
      setStartDate("");
      setEndDate("");
      if (filterValue.value === "Last 7 days") {
        lastMoment = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 30 days") {
        lastMoment = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
      } else if (filterValue.value === "Last 3 months") {
        lastMoment = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
      }
      if (filterValue.value === "Last 12 months") {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear() - 1;
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        requiredDate = `${year}-${month}-${day}`;
        setSelectedDate(requiredDate);
        return;
      } else {
        let day = lastMoment.getDate();
        let month = lastMoment.getMonth() + 1;
        let year = lastMoment.getFullYear();
        requiredDate = `${year}-${month}-${day}`;
        setSelectedDate(requiredDate);
      }
    }
  }, [filterValue]);

  const handleExportClick = (e) => {
    e.preventDefault();

    let day = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let requiredDate = `${year}-${month}-${day}`;

    const payload = {
      title_filter: selectedTitle?.value,
      patient_filter: selectedPatient?.value,
      user_filter: selectedUsers?.value,
      task_filter:selectedTask?.value,
      recipient:selectedRecipient?.value,
      created_gte_filter:
        filterValue.value === "Custom date range"
          ? moment(startDate).format("YYYY-MM-DD")
          : selectedDate,
      created_lte_filter:
        filterValue.value === "Custom date range"
          ? moment(endDate).format("YYYY-MM-DD")
          : requiredDate,
    };

    if (!payload.title) {
      delete payload.title;
    }
    if (!payload.patient_filter) {
      delete payload.patient_filter;
    }
    if (!payload.user_filter) {
      delete payload.user_filter;
    }
    if (!payload.task_filter) {
      delete payload.task_filter;
    }
    if (!payload.recipient) {
      delete payload.recipient;
    }
    window.analytics.track("Notes-Export-Submit",{
      ...payload
    })
    setSavingNotes(true);
    dispatch(exportNotes(payload)).then(() =>{
      setSavingNotes(false);
      onClose();
    }
     );
  };

  useEffect(() => {
    dispatch(getNoteUsers()).then((res) =>
      setUserOptions(
        res.map((itm) => ({
          value: itm.id,
          label:
              itm.email ? itm.email : "N/A",
          category: "users",
        }))
      )
    );
  }, []);

  const onInputChange = debounce(async (inputValue) => {
    if (inputValue) {
      setLoading(true);
      setPatientsList([]);
      setQuery(inputValue);

      const res = await dispatch(getNotePatients(inputValue));
      if (res) {
        setPatientsList(
          res.map((itm) => ({
            value: itm.id,
            label:
              itm.first_name && itm.last_name
                ? itm.first_name + " " + itm.last_name
                : "N/A",
            category: "users",
          }))
        );
      }
      setLoading(false);
    } else {
      setPatientsList([]);
    }
  }, 1000);

  const onInputChangeTitle = debounce(async (inputValue) => {
    if (inputValue) {
      setLoadingTitle(true);
      setTitleList([]);
      setQueryTitle(inputValue);

      const res = await dispatch(getNoteTitle(inputValue));
      if (res) {
        setTitleList(
          res.map((itm) => ({ label: itm.title, value: itm.title }))
        );
      }
      setLoadingTitle(false);
    } else {
      setTitleList([]);
    }
  }, 1000);

  return (
    <Modal
      isOpen={opened}
      onRequestClose={onClose}
      contentLabel="Save Filter Set"
      style={customStyle}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2 style={{ fontSize: "24px", color: "#0C2945", fontWeight: 700, fontFamily:'Roboto Condensed' }}>
          Export Notes
        </h2>
        <p style={{ cursor: "pointer" }} onClick={onClose}>
          x
        </p>
      </div>

      <form style={{ width: "100%" }} onSubmit={handleExportClick}>
        <div style={{ display: "flex", gap: "16px" }}>
          
        <div style={{flexBasis:'50%'}}>
            <SelectContainer>
              <SelectDropdownTitle> Task</SelectDropdownTitle>
              <Select
                components={{ Option:IndicatorsContainer }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={taskOptions}
                value={selectedTask}
                onChange={(value) => setSelectedTask(value)}
                styles={{
                  option: (styles) => ({ ...styles, fontSize: "14px" }),
                }}
              />
            </SelectContainer>
          </div>
          <div style={{flexBasis:'50%'}}>
            <SelectContainer>
              <SelectDropdownTitle>Recipient</SelectDropdownTitle>
              <Select
                components={{ Option:IndicatorsContainer }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={recipientOptions}
                value={selectedRecipient}
                onChange={(value) => setSelectedRecipient(value)}
                styles={{
                  option: (styles) => ({ ...styles, fontSize: "14px" }),
                }}
              />
            </SelectContainer>
          </div>
          
         
        </div>
        <div style={{ display: "flex", gap: "16px" }}>
        <div style={{flexBasis:'50%'}}>
            <TimeframeContainer>
            <SelectDropdownTitle>Created *</SelectDropdownTitle>
            <Filter
              options={options}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setSelectedDate={setSelectedDate}
              type="radio"
            />
          </TimeframeContainer>
            </div>
         
         
          <div style={{flexBasis:'50%'}}>
            <SelectContainer>
              <SelectDropdownTitle>Title</SelectDropdownTitle>
              <Select
                components={{Option: IndicatorsContainer }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={titleList}
                value={selectedTitle}
                onChange={(value) => setSelectedTitle(value)}
                styles={{
                  option: (styles) => ({ ...styles, fontSize: "14px" }),
                }}
                onInputChange={onInputChangeTitle}
                placeholder={loadingTitle ? "Searching" : "Search..."}
                isLoading={loadingTitle}
              />
            </SelectContainer>
          </div>
        </div>
        <div style={{ display: "flex", gap: "16px" }}>
        <div style={{flexBasis:'50%'}}>
            <SelectContainer>
              <SelectDropdownTitle>User</SelectDropdownTitle>
              <Select
                components={{Option: IndicatorsContainer }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={userOptions}
                value={selectedUsers}
                onChange={(value) => setSelectedUsers(value)}
                styles={{
                  option: (styles) => ({ ...styles, fontSize: "14px" }),
                }}
              />
            </SelectContainer>
          </div>
          <div style={{flexBasis:'50%'}}>
            <SelectContainer>
              <SelectDropdownTitle>Patient</SelectDropdownTitle>
              <Select
                components={{Option: IndicatorsContainer }}
                className="basic-multi-select"
                classNamePrefix="select"
                options={patientsList}
                value={selectedPatient}
                onChange={(value) => setSelectedPatient(value)}
                styles={{
                  option: (styles) => ({ ...styles, fontSize: "14px" }),
                }}
                onInputChange={onInputChange}
                placeholder={loading ? "Searching" : "Search..."}
                isLoading={loading}
              />
            </SelectContainer>
          </div>
          </div>
   
        
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
           disabled={!filterValue}
            style={{
              background:  !filterValue? '#00AEEF4D' :"#00AEEF",
              color: "white",
              border: "none",
              borderRadius: "4px",
              width: "227px",
              height:'38px'
            }}
            type="submit"
          >
            {
              savingNotes? <Spinner animation="border" role="status" size="sm" />  : 'Export'
            }
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NotesExportModal;
